export const users = [
    {
        "user_id": "e052f135-13db-4a0d-aa15-f9bffac00359",
        "email": "test@gmail.com",
        "hashed_password": "$2y$10$gYOVSYwy/SJvkoWRBQvkNu2So9RTMa./.zFq8bj1Edm9zn.e92mma",
        "first_name": "Test",
        "last_name": "Family",
        "created_at": "2024-11-19 11:41:11"
    },
    {
        "user_id": "f4f434b3-f256-484b-8935-29e13126c9e8",
        "email": "carla@gmail.com",
        "hashed_password": "$2y$10$hc/SewW8/cyVHDuSHHXDyOyrzbPrwcFjAIjXHOWqwAsLn0QxHfF4i",
        "first_name": "Carla",
        "last_name": "Wilson",
        "created_at": "2024-11-19 11:41:11"
    }
]